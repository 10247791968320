const useUrl = () => {
  var url = "";
  if (process.env.NODE_ENV === "development") {
    url = `http://localhost:9000`;
  } else {
    url = `https://www.app.dotphrase.ai`;
  }
  return url;
};

export const useReactUrl = () => {
  var url = "";
  if (process.env.NODE_ENV === "development") {
    url = `http://localhost:3000`;
  } else {
    url = `https://www.app.dotphrase.ai`;
  }
  return url;
};

export default useUrl;
