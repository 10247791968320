import { Navigate, Route, Routes } from "react-router";
import Home from "./containers/Home";
import Subscribe from "./containers/Subscribe";

export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/subscribe" element={<Subscribe />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}
