import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Button,
  Card,
  Fade,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Dictation } from "../types/Dictation";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "300px",
  width: "80%",
  bgcolor: "#2c3e50",
  boxShadow: 24,
  p: 2,
  borderRadius: 2,
  m: 0,
  overflowY: "scroll",
  maxHeight: "90%",
};

type RecentDictationsProps = {
  open: boolean;
  appendNotes: (notes: string) => void;
  handleClose: () => void;
  dictations: Dictation[];
};

const DictationsTable = (props: {
  dictations: Dictation[];
  appendNotes: (notes: string) => void;
  handleClose: () => void;
}): JSX.Element => (
  <TableContainer component={Paper}>
    <Table>
      <TableBody>
        {props.dictations.map((d) => (
          <TableRow
            key={d.id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell>
              <Card
                onClick={() => {
                  props.appendNotes(d.text);
                  props.handleClose();
                }}
              >
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Typography variant="body2">
                    {d.text.length < 150
                      ? d.text
                      : `${d.text.slice(0, 250)}...`}
                  </Typography>
                  <IconButton sx={{ width: "24px" }}>
                    <AddIcon />
                  </IconButton>
                </Stack>
              </Card>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const RecentDictationsModal = (props: RecentDictationsProps): JSX.Element => {
  const { open, appendNotes, handleClose, dictations } = props;
  const [page, setPage] = useState(0);
  const pageSize = 3;
  const sortByCreated = (a: Dictation, b: Dictation) =>
    a.created > b.created ? -1 : 1;
  const dictationSlice = dictations
    .sort(sortByCreated)
    .slice(page * pageSize, page * pageSize + pageSize);
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Box sx={style}>
            <Stack direction={"column"} spacing={2}>
              <Box>
                <Paper>
                  <DictationsTable
                    dictations={dictationSlice}
                    appendNotes={appendNotes}
                    handleClose={handleClose}
                  />
                  <TablePagination
                    component="div"
                    count={dictations.length}
                    rowsPerPage={pageSize}
                    rowsPerPageOptions={[]}
                    page={page}
                    onPageChange={(_, i) => setPage(i)}
                  />
                </Paper>
              </Box>
              <Button
                size="large"
                color="warning"
                onClick={handleClose}
                variant="contained"
              >
                CLOSE
              </Button>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default RecentDictationsModal;
