import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import Menu from "../components/Menu";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import useUrl, { useReactUrl } from "../hooks/url";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";

const SellingPoint = (props: { text: string }): JSX.Element => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <CheckCircleOutlineIcon />
      <Typography variant="subtitle2" color="white">
        {props.text}
      </Typography>
    </Stack>
  );
};

const Subscribe = (): JSX.Element => {
  const url = useUrl();
  const reactUrl = useReactUrl();
  const { isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [trialDaysRemaining, setTrialDaysRemaining] = useState<number>(14);
  const [subscribed, setSubscribed] = useState<boolean>(true);

  // Fetch and save access token
  useEffect(() => {
    if (!isLoading && !accessToken && url) {
      getAccessTokenSilently({ redirect_uri: reactUrl + "/subscribe" })
        .then((t) => {
          setAccessToken(t);
        })
        .catch(async (e) => {
          console.error(e);
          loginWithRedirect({ redirectUri: reactUrl + "/subscribe" }).then(
            () => {
              getAccessTokenSilently({
                redirect_uri: reactUrl + "/subscribe",
              }).then((t) => {
                setAccessToken(t);
              });
            }
          );
        });
    }
  }, [
    getAccessTokenSilently,
    loginWithRedirect,
    isLoading,
    accessToken,
    url,
    reactUrl,
  ]);

  const handleSubscribe = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    };
    fetch(url + "/subscriptions/checkout", requestOptions).then((response) => {
      response.json().then((data) => {
        const url = data.url;
        window.location.href = url;
      });
    });
  };

  useEffect(() => {
    if (accessToken && url) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      fetch(url + "/subscriptions/status", requestOptions).then((response) => {
        response.json().then((data) => {
          const subscribed = data.subscribed;
          setSubscribed(subscribed);
          setTrialDaysRemaining(data.trialDaysRemaining);
          if (subscribed) {
            window.location.href = "/";
          }
        });
      });
    }
  }, [accessToken, url]);

  const LoadingView = (): JSX.Element => {
    return (
      <Box alignItems="center">
        <Stack spacing={2} alignItems="center">
          <Menu
            trialDaysRemaining={trialDaysRemaining}
            subscribed={true}
            accessToken={accessToken}
          />
          <CircularProgress />
        </Stack>
      </Box>
    );
  };

  if (isLoading || !accessToken || !url) {
    return <LoadingView />;
  }

  return (
    <Stack>
      <Menu
        trialDaysRemaining={trialDaysRemaining}
        subscribed={subscribed}
        accessToken={accessToken}
      />
      <Stack sx={{ m: 2 }} alignItems="center">
        <Typography variant="h5" sx={{ mt: 2 }} color="white">
          <b>Subscribe to continue using Dotphrase AI</b>
        </Typography>
        <Box
          sx={{
            m: 2,
            p: 2,
            color: "#FFF",
            border: 1,
            borderRadius: 2,
            maxWidth: "400px",
          }}
        >
          <Stack alignItems="center" spacing={2}>
            <Stack alignItems="center" spacing={1}>
              <Typography variant="subtitle1" color="#3498db">
                <b>Pro</b>
              </Typography>
              <Typography variant="h5" color="white">
                <b>$9.99</b> / mo
              </Typography>
              <Typography variant="subtitle2" color="white">
                <b>Supercharge your workflow with Dotphrase Pro</b>
              </Typography>
              <Stack alignItems="start" spacing={1} mt={2}>
                <SellingPoint text="Unlimited templates" />
                <SellingPoint text="Unlimited dictation" />
                <SellingPoint text="Access to advanced models" />
                <SellingPoint text="Priority support" />
              </Stack>
            </Stack>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#3498db",
                width: "80%",
                p: 1,
              }}
              onClick={handleSubscribe}
            >
              Subscribe
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};

export default Subscribe;
