import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useState } from "react";
import useUrl from "../hooks/url";

type Props = {
  accessToken: string | undefined;
};

const Profile = (props: Props): JSX.Element => {
  const { user, isAuthenticated, isLoading, loginWithRedirect, logout } =
    useAuth0();

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLogout = () => {
    logout({ returnTo: "https://dotphrase.ai" });
  };

  const url = useUrl();

  const handlePortal = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.accessToken}`,
      },
    };
    fetch(url + "/subscriptions/portal", requestOptions).then((response) => {
      response.json().then((data) => {
        const url = data.url;
        if (!url) {
          window.location.href = "/subscribe";
        }
        window.location.href = url;
      });
    });
  };

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return isAuthenticated && user ? (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings" enterTouchDelay={0}>
        <IconButton onClick={handleOpenUserMenu}>
          <Avatar alt={user.name} src={user.picture} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          key={"subscriptions"}
          onClick={handlePortal}
          sx={{ p: 2, justifyContent: "center" }}
        >
          Manage Subscription
        </MenuItem>
        <Divider />
        <MenuItem
          key={"help"}
          onClick={() => {
            window.location.href = "https://dotphrase.notion.site";
          }}
          sx={{ p: 2, justifyContent: "center" }}
        >
          User Guide
        </MenuItem>
        <Divider />
        <MenuItem
          key={"logout"}
          onClick={handleLogout}
          sx={{ p: 2, justifyContent: "center" }}
        >
          Logout
        </MenuItem>
      </Menu>
    </Box>
  ) : (
    <Button onClick={loginWithRedirect} color="inherit">
      Login
    </Button>
  );
};

export default Profile;
