import {
  Stack,
  List,
  Button,
  ListItemButton,
  ListItemText,
  Divider,
  Typography,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import Template from "../types/Template";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";

type TemplateListProps = {
  templates: Template[];
  selectedTemplate: number | null;
  setSelectedTemplate: (id: number) => void;
  handleNewTemplate: () => void;
  handleDeleteTemplate: (id: number) => void;
  handleCloneTemplate: (id: number) => void;
};

export const TemplateList = (props: TemplateListProps): JSX.Element => {
  const {
    templates,
    selectedTemplate,
    setSelectedTemplate,
    handleNewTemplate,
    handleDeleteTemplate,
    handleCloneTemplate,
  } = props;

  return (
    <Stack
      spacing={1}
      direction="column"
      sx={{ marginLeft: 2, marginRight: 2, marginTop: 1, marginBottom: 1 }}
    >
      <Typography variant="h6" color="white">
        Sample Templates
      </Typography>
      {templates && selectedTemplate && (
        <List>
          {templates
            .filter((t) => t.base === true)
            .map((t) => (
              <Stack
                direction="column"
                spacing={0}
                alignItems="start"
                key={t.id}
              >
                <Box
                  sx={{ display: "flex", width: "100%" }}
                  justifyContent="space-between"
                >
                  <ListItemButton
                    key={t.id}
                    sx={{ color: "white", width: "100%" }}
                    onClick={() => setSelectedTemplate(t.id)}
                    selected={t.id === selectedTemplate}
                  >
                    <ListItemText primary={t.title} />
                  </ListItemButton>
                  <Tooltip
                    title="Clone a sample template to quickly start building a note."
                    enterTouchDelay={0}
                  >
                    <IconButton
                      sx={{ p: 1 }}
                      onClick={() => {
                        handleCloneTemplate(t.id);
                      }}
                    >
                      <LibraryAddIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Divider sx={{ width: "100%" }} />
              </Stack>
            ))}
        </List>
      )}
      <Typography variant="h6" color="white">
        My Templates
      </Typography>
      {templates && selectedTemplate && (
        <List>
          {templates
            .filter((t) => t.base !== true)
            .map((t) => (
              <Stack
                direction="column"
                spacing={0}
                alignItems="start"
                key={t.id}
              >
                <Box
                  sx={{ display: "flex", width: "100%" }}
                  justifyContent="space-between"
                >
                  <ListItemButton
                    key={t.id}
                    sx={{ color: "white", width: "100%" }}
                    onClick={() => setSelectedTemplate(t.id)}
                    selected={t.id === selectedTemplate}
                  >
                    <ListItemText primary={t.title} />
                  </ListItemButton>
                  <IconButton
                    sx={{ p: 1 }}
                    onClick={() => {
                      handleDeleteTemplate(t.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ width: "100%" }} />
              </Stack>
            ))}
        </List>
      )}
      <Button
        variant="outlined"
        sx={{ p: 1 }}
        startIcon={<AddIcon />}
        onClick={handleNewTemplate}
      >
        Create New Note Type
      </Button>
    </Stack>
  );
};
