import {
  Box,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { Generation } from "../types/Generation";
import RefreshIcon from "@mui/icons-material/Refresh";
import { LoadingButton } from "@mui/lab";

type GenerationProps = {
  generations: Generation[];
  handleGenerationClick: (id: string) => () => void;
  refreshGenerations: () => void;
  loading: boolean;
  page: number;
  setPage: (i: number) => void;
  hidden: boolean;
};

export const Generations = (props: GenerationProps): JSX.Element => {
  const {
    page,
    setPage,
    generations,
    handleGenerationClick,
    refreshGenerations,
    loading,
    hidden,
  } = props;

  const pageSize = 5;
  const sortByCreated = (a: Generation, b: Generation) =>
    a.created > b.created ? -1 : 1;

  if (hidden) {
    return <div></div>;
  }

  return (
    <Box sx={{ margin: 2 }}>
      <Paper>
        <Toolbar sx={{ p: 1 }}>
          <Typography sx={{ flex: "1 1 100%" }} component="div" variant="h6">
            Recent Documents
          </Typography>
          <IconButton onClick={refreshGenerations}>
            <RefreshIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="body1">Anonymous ID</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">Documents</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {generations
                .sort(sortByCreated)
                .slice(page * pageSize, page * pageSize + pageSize)
                .map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography>{row.humanId}</Typography>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <LoadingButton
                        variant="outlined"
                        loading={loading}
                        onClick={handleGenerationClick(row.id)}
                        color="info"
                        sx={{ width: "100%" }}
                      >
                        <pre style={{ fontFamily: "inherit" }}>
                          {`${new Date(row.created).toLocaleString()}\n`}
                          {`${row.documents.length + 1} documents`}
                        </pre>
                      </LoadingButton>
                    </TableCell>
                  </TableRow>
                ))}
              {generations.length === 0 && (
                <TableRow>
                  <TableCell>
                    You haven't generated any documents yet.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={generations.length}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[]}
          page={page}
          onPageChange={(_, i) => setPage(i)}
        />
      </Paper>
    </Box>
  );
};
