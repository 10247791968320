import {
  Stack,
  FormControl,
  TextField,
  Typography,
  Box,
  IconButton,
  IconButtonProps,
  styled,
  Collapse,
  Slider,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
  Button,
  Tooltip,
} from "@mui/material";
import Template from "../types/Template";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useCallback, useRef, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import DeleteIcon from "@mui/icons-material/Delete";
import { Recording } from "../types/Recording";
import CircleIcon from "@mui/icons-material/Circle";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import InfoIcon from "@mui/icons-material/Info";

type TemplateFormProps = {
  templates: Template[];
  template: Template | undefined;
  handleTemplateChange: (template: Template) => void;
  handleSaveTemplateChange: (template: Template) => void;
  handleStartTemplateRecording: () => void;
  handleStopRecording: () => void;
  outputRef: React.RefObject<HTMLTextAreaElement>;
  loading: boolean;
  loadingDictation: boolean;
  dispatchTemplateRecordings: (recordings: Recording | null) => void;
  handleTemplateRecordingsChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(90deg)" : "rotate(0deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export const TemplateForm = (props: TemplateFormProps): JSX.Element => {
  const {
    template,
    templates,
    handleTemplateChange,
    handleSaveTemplateChange,
    handleStartTemplateRecording,
    handleStopRecording,
    loading,
    loadingDictation,
    dispatchTemplateRecordings,
    handleTemplateRecordingsChange,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  var recording = useRef(false);
  const selectedBaseTemplate = templates.find(
    (t) => t.id === template?.id && t.base
  )?.base;

  const downTouchListener: React.TouchEventHandler<HTMLButtonElement> =
    useCallback(() => {
      if (recording.current === false) {
        recording.current = true;
        handleStartTemplateRecording();
      }
    }, [handleStartTemplateRecording]);

  const upTouchListener: React.TouchEventHandler<HTMLButtonElement> =
    useCallback(() => {
      recording.current = false;
      handleStopRecording();
    }, [handleStopRecording]);

  const downMouseListener: React.MouseEventHandler = useCallback(() => {
    if (recording.current === false) {
      recording.current = true;
      handleStartTemplateRecording();
    }
  }, [handleStartTemplateRecording]);

  const upMouseListener: React.MouseEventHandler = useCallback(() => {
    recording.current = false;
    handleStopRecording();
  }, [handleStopRecording]);

  if (template) {
    const duplicateTitle = templates
      .filter((t) => t.id !== template?.id)
      .some((t) => t.title === template?.title);
    const emptyTitle =
      template.title === "" || template.title.trim().length < 1;

    return (
      <Stack
        alignItems={"start"}
        sx={{ marginLeft: 2, marginRight: 2, marginTop: 1, marginBottom: 1 }}
        spacing={2}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h6" color="white">
            Build Your Note
          </Typography>
          {template.saved ? (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              color="#55E6C1"
              sx={{ p: 1, border: "1px solid #55E6C1", borderRadius: 2 }}
            >
              <CheckCircleIcon />
              <Typography variant="body2">Saved</Typography>
            </Stack>
          ) : (
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{
                p: 1,
                border: "1px solid #F8EFBA",
                borderRadius: 2,
                color: "#F8EFBA",
              }}
            >
              <WarningIcon />
              <Typography variant="body2">Unsaved Changes</Typography>
            </Stack>
          )}
        </Box>
        <FormControl sx={{ width: "100%" }}>
          <TextField
            label="Name this Note Type"
            value={template.title || ""}
            onChange={(event) => {
              template.saved = false;
              template.title = event.target.value;
              handleTemplateChange(template);
            }}
            disabled={selectedBaseTemplate}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <Tooltip
                  title="This field is for your convenience and has no effect on the output"
                  enterTouchDelay={0}
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <TextField
            label="Provide Instructions for the A.I."
            multiline
            minRows={3}
            maxRows={8}
            value={template.purpose || ""}
            onChange={(event) => {
              template.saved = false;
              template.purpose = event.target.value;
              handleTemplateChange(template);
            }}
            disabled={selectedBaseTemplate}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <Tooltip
                  title="Issue commands for the A.I. to follow. See the sample templates for examples."
                  enterTouchDelay={0}
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <TextField
            label="Type or speak an example input (optional but recommended for notes)"
            multiline
            minRows={4}
            maxRows={8}
            value={template.sample_dictation || ""}
            onChange={handleTemplateRecordingsChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <Stack
                  spacing={1}
                  sx={{ marginRight: "-6px", alignSelf: "flex-end" }}
                  alignItems="center"
                >
                  {loadingDictation && <CircularProgress size={20} />}

                  <Tooltip
                    title="Give the A.I. an example of an input you would enter yourself. See the sample templates for examples."
                    enterTouchDelay={0}
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                  <IconButton
                    onClick={() => dispatchTemplateRecordings(null)}
                    disabled={loading}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
              ),
            }}
            disabled={selectedBaseTemplate}
          />
        </FormControl>
        <Tooltip
          title="Press and hold this button to begin dictation"
          enterTouchDelay={0}
        >
          <Button
            startIcon={
              recording.current ? <CircleIcon /> : <KeyboardVoiceIcon />
            }
            variant="outlined"
            sx={{ width: "100%", height: 48 }}
            disabled={loading || selectedBaseTemplate}
            onTouchStart={downTouchListener}
            onTouchEnd={upTouchListener}
            onMouseDown={downMouseListener}
            onMouseUp={upMouseListener}
          >
            {recording.current ? "Recording..." : "Record"}
          </Button>
        </Tooltip>
        <FormControl sx={{ width: "100%" }}>
          <TextField
            label="Teach the Model: Type your ideal output using the input above"
            value={template.sample_output || ""}
            multiline
            minRows={3}
            maxRows={10}
            onChange={(event) => {
              template.saved = false;
              template.sample_output = event.target.value;
              handleTemplateChange(template);
            }}
            disabled={selectedBaseTemplate}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <Tooltip
                  title="Give the A.I. an example of an output you would like to see. This helps the A.I model its output correctly. See the sample templates for examples."
                  enterTouchDelay={0}
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <TextField
            label="Put any other commands for the model here (optional)"
            value={template.other_instructions || ""}
            multiline
            maxRows={8}
            onChange={(event) => {
              template.saved = false;
              template.other_instructions = event.target.value;
              handleTemplateChange(template);
            }}
            disabled={selectedBaseTemplate}
          />
        </FormControl>
        <Box
          sx={{ display: "flex", width: "100%", alignContent: "center" }}
          justifyContent="space-between"
          onClick={handleExpandClick}
        >
          <Typography variant="body1" color="white" alignSelf="center">
            Advanced Settings
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Box>
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
          sx={{ width: "100%" }}
        >
          <Stack spacing={2} sx={{ maxWidth: "400px" }}>
            <Typography variant="body2" color="white">
              Model{" "}
              <Tooltip
                title="We recommend ChatGPT for most tasks given its speed."
                enterTouchDelay={0}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <FormControl>
              <InputLabel>Model </InputLabel>
              <Select
                label="Model"
                value={template.model || "gpt-3.5-turbo"}
                onChange={(event) => {
                  if (typeof event.target.value === "string") {
                    template.saved = false;
                    template.model = event.target.value;
                    handleTemplateChange(template);
                  }
                }}
                disabled={selectedBaseTemplate}
              >
                <MenuItem value={"gpt-4"}>GPT-4</MenuItem>
                <MenuItem value={"gpt-3.5-turbo"}>ChatGPT</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body2" color="white">
              Temperature{" "}
              <Tooltip
                title="Higher temperatures and top P correspond to more “randomness”. Use higher settings for prose, like patient letters. Use lower settings for structured documents like clinic notes"
                enterTouchDelay={0}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <Slider
              aria-label="temp"
              value={template.temp}
              step={0.01}
              min={0}
              max={1}
              valueLabelDisplay="auto"
              onChange={(_, value) => {
                if (typeof value === "number") {
                  template.saved = false;
                  template.temp = value;
                  handleTemplateChange(template);
                }
              }}
              disabled={selectedBaseTemplate}
            />
            <Typography variant="body2" color="white">
              Top P{" "}
              <Tooltip
                title="Higher temperatures and top P correspond to more “randomness”. Use higher settings for prose, like patient letters. Use lower settings for structured documents like clinic notes"
                enterTouchDelay={0}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Typography>
            <Slider
              aria-label="top p"
              value={template.top_p}
              step={0.01}
              min={0}
              max={1}
              valueLabelDisplay="auto"
              onChange={(_, value) => {
                if (typeof value === "number") {
                  template.saved = false;
                  template.top_p = value;
                  handleTemplateChange(template);
                }
              }}
              disabled={selectedBaseTemplate}
            />
          </Stack>
        </Collapse>
        {duplicateTitle && (
          <Typography variant="body2" color="#e67e22">
            You already have a template with this title.
          </Typography>
        )}
        {emptyTitle && (
          <Typography variant="body2" color="#e67e22">
            Please enter a title.
          </Typography>
        )}
        {selectedBaseTemplate && (
          <Typography variant="body2" color="#e67e22">
            You cannot edit a sample template. Try making a copy in the sidebar.
          </Typography>
        )}
        <LoadingButton
          sx={{
            width: "100%",
            backgroundColor: "#2a7de1",
            minHeight: 48,
          }}
          variant="contained"
          disabled={
            duplicateTitle ||
            template.title.trim().length === 0 ||
            selectedBaseTemplate
          }
          startIcon={<SaveIcon />}
          onClick={() => {
            handleSaveTemplateChange(template);
            template.saved = true;
          }}
        >
          Save Changes
        </LoadingButton>
      </Stack>
    );
  } else {
    return (
      <Typography color="white" variant="body1" sx={{ p: 2 }}>
        You haven't created any templates yet. Try creating one.
      </Typography>
    );
  }
};
