import SvgIcon from "@mui/material/SvgIcon/SvgIcon";

const SubmitIcon = (
  <SvgIcon>
    <svg
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.75024 10H4.75024L8.50024 1.75L14.5002 17.5L18.2502 10H21.2502"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </SvgIcon>
);

export default SubmitIcon;
