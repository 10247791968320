import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import SubmitIcon from "../assets/SubmitIcon";
import Template from "../types/Template";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import { Dictation } from "../types/Dictation";
import RecentDictationsModal from "./RecentDictationsModal";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import CircleIcon from "@mui/icons-material/Circle";
import { useCallback, useEffect, useRef } from "react";
import { Recording } from "../types/Recording";

type FormProps = {
  loading: boolean;
  loadingDictation: boolean;
  templates: Template[];
  selectedTemplates: string[];
  recordings: string[];
  dispatchRecordings: (recordings: Recording | null) => void;
  recordingsIndex: number;
  noPHI: boolean;
  handleDocumentsChange: (event: SelectChangeEvent<string[]>) => void;
  handleNotesChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: any) => void;
  togglePHIAttestation: (event: any) => void;
  handleStartRecording: () => void;
  handleStopRecording: () => void;
  recentDictationsModalOpen: boolean;
  setRecentDictationsModalOpen: (open: boolean) => void;
  dictations: Dictation[];
  outputRef: React.RefObject<HTMLTextAreaElement>;
};

const Form = (props: FormProps): JSX.Element => {
  const {
    loading,
    loadingDictation,
    templates,
    selectedTemplates,
    noPHI,
    recordings,
    dispatchRecordings,
    recordingsIndex,
    recentDictationsModalOpen,
    setRecentDictationsModalOpen,
    dictations,
    handleDocumentsChange,
    handleNotesChange,
    handleSubmit,
    togglePHIAttestation,
    handleStartRecording,
    handleStopRecording,
    outputRef,
  } = props;

  const inputRef = useRef<HTMLTextAreaElement>();
  var recording = useRef(false);

  const downKeyListener = useCallback(
    (event: KeyboardEvent) => {
      if (recording.current === true) {
        event.preventDefault();
        return;
      } else if (
        event.key === " " &&
        !(document.activeElement === inputRef.current) &&
        !(document.activeElement === outputRef.current)
      ) {
        event.preventDefault();
        recording.current = true;
        handleStartRecording();
      }
    },
    [handleStartRecording, outputRef]
  );

  const upKeyListener = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.key === " " &&
        !(document.activeElement === inputRef.current) &&
        !(document.activeElement === outputRef.current)
      ) {
        recording.current = false;
        handleStopRecording();
      }
    },
    [handleStopRecording, outputRef]
  );

  const downTouchListener: React.TouchEventHandler<HTMLButtonElement> =
    useCallback(() => {
      if (recording.current === false) {
        recording.current = true;
        handleStartRecording();
      }
    }, [handleStartRecording]);

  const upTouchListener: React.TouchEventHandler<HTMLButtonElement> =
    useCallback(() => {
      recording.current = false;
      handleStopRecording();
    }, [handleStopRecording]);

  const downMouseListener: React.MouseEventHandler = useCallback(() => {
    if (recording.current === false) {
      recording.current = true;
      handleStartRecording();
    }
  }, [handleStartRecording]);

  const upMouseListener: React.MouseEventHandler = useCallback(() => {
    recording.current = false;
    handleStopRecording();
  }, [handleStopRecording]);

  useEffect(() => {
    document.addEventListener("keydown", downKeyListener);
    document.addEventListener("keyup", upKeyListener);

    return () => {
      document.removeEventListener("keydown", downKeyListener);
      document.removeEventListener("keyup", upKeyListener);
    };
  }, [downKeyListener, upKeyListener]);

  useEffect(() => {
    if (inputRef.current && (loadingDictation || recordings.length > 1)) {
      inputRef.current.scrollTop = inputRef.current.scrollHeight;
    }
  }, [loadingDictation, recordings]);

  return (
    <Stack spacing={2} alignItems={"center"} sx={{ margin: 2 }}>
      <Box sx={{ width: "100%" }}>
        <Stack alignItems={"start"}>
          <FormControl sx={{ paddingBottom: 2, width: "100%" }}>
            <InputLabel>Documents</InputLabel>
            <Select
              id="document-types"
              multiple
              value={selectedTemplates}
              onChange={handleDocumentsChange}
              input={<OutlinedInput label="Documents" />}
              renderValue={(selected) => selected.join(", ")}
              sx={{ ":focus": { borderColor: "#FFFFFF" } }}
              disabled={loading}
              MenuProps={{ PaperProps: { sx: { maxHeight: "75%" } } }}
            >
              {templates
                .sort((a, b) => (a.title > b.title ? 1 : -1))
                .map((document) => (
                  <MenuItem key={document.id} value={document.title}>
                    <Checkbox
                      checked={selectedTemplates.indexOf(document.title) > -1}
                    />
                    <ListItemText primary={document.title} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl
            variant="filled"
            sx={{ paddingBottom: 2, width: "100%" }}
          >
            <TextField
              minRows={8}
              maxRows={12}
              label="Notes"
              placeholder="Place the clinical context here. For notes, start with a one-liner, and 
              go through the assessment and plan. For example: &#10;This is a 54 year old with a history of diabetes who comes in with six days of
              shortness of breath. He states it started after ... "
              multiline
              variant="filled"
              onChange={handleNotesChange}
              value={recordings.join(" ")}
              disabled={loadingDictation}
              inputRef={inputRef}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <Stack
                    spacing={1}
                    sx={{ marginRight: "-6px", alignSelf: "flex-end" }}
                    alignItems="center"
                  >
                    {loadingDictation && <CircularProgress size={20} />}
                    <IconButton
                      onClick={() => dispatchRecordings(null)}
                      disabled={loading}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                ),
              }}
            />
          </FormControl>
          <ButtonGroup sx={{ width: "100%" }}>
            <Tooltip
              title="View and import your recent dictations"
              enterTouchDelay={0}
            >
              <Button
                startIcon={<AddIcon />}
                onClick={() => setRecentDictationsModalOpen(true)}
                variant="outlined"
                sx={{ width: "100%", height: 48, marginBottom: 2 }}
                disabled={loading}
              >
                Import
              </Button>
            </Tooltip>
            <Tooltip
              title="Press and hold the spacebar or this button to begin dictation"
              enterTouchDelay={0}
            >
              <Button
                startIcon={
                  recording.current ? <CircleIcon /> : <KeyboardVoiceIcon />
                }
                variant="outlined"
                sx={{ width: "100%", height: 48 }}
                disabled={loading}
                onTouchStart={downTouchListener}
                onTouchEnd={upTouchListener}
                onMouseDown={downMouseListener}
                onMouseUp={upMouseListener}
              >
                {recording.current ? "Recording..." : "Record"}
              </Button>
            </Tooltip>
          </ButtonGroup>
          <FormControlLabel
            control={
              <Checkbox checked={noPHI} onClick={togglePHIAttestation} />
            }
            label={
              <Typography
                color="textPrimary"
                variant="body2"
                sx={{ paddingLeft: 1 }}
              >
                I attest that I have not entered PHI into the above notes.
              </Typography>
            }
            sx={{ paddingBottom: 2, width: "100%" }}
          />
          <LoadingButton
            sx={{
              width: "100%",
              backgroundColor: "#2a7de1",
              minHeight: 48,
            }}
            variant="contained"
            endIcon={SubmitIcon}
            loading={loading}
            onClick={handleSubmit}
            disabled={
              loading ||
              !selectedTemplates.length ||
              !recordings.length ||
              !noPHI
            }
          >
            Generate Documents
          </LoadingButton>
        </Stack>
      </Box>
      <RecentDictationsModal
        open={recentDictationsModalOpen}
        appendNotes={(n) =>
          dispatchRecordings({ index: recordingsIndex, recording: " " + n })
        }
        handleClose={() => setRecentDictationsModalOpen(false)}
        dictations={dictations}
      />
    </Stack>
  );
};

export default Form;
