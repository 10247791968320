import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  Modal,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { SelectChangeEvent } from "@mui/material/Select";
import useUrl from "../hooks/url";
import Form from "../components/Form";
import { Result } from "../types/Result";
import { Generation } from "../types/Generation";
import Results from "../components/Results";
import Menu from "../components/Menu";
import { useAuth0 } from "@auth0/auth0-react";
import CircularProgress from "@mui/material/CircularProgress";
import Template from "../types/Template";
import { Generations } from "../components/Generations";
import { Dictation } from "../types/Dictation";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { useReducer } from "react";
import { words } from "../words";
import { Recording } from "../types/Recording";
import { TabContext, TabPanel } from "@mui/lab";
import { TemplateList } from "../components/TemplateList";
import { TemplateForm } from "../components/TemplateForm";
import { Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const App = (): JSX.Element => {
  const url = useUrl();
  const templatesReducer = (
    state: Template[],
    action: Template | Template[]
  ) => {
    if (action instanceof Array) return action;
    else if (state.findIndex((template) => template.id === action.id) === -1)
      return [...state, action];
    else
      return state.map((template) =>
        template.id === action.id ? action : template
      );
  };

  const [templates, setTemplates] = useReducer(templatesReducer, []);
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [humanId, setHumanId] = useState("");
  const [expectedDocumentCount, setExpectedDocumentCount] = useState(0);
  const [noPHI, setNoPHI] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingGeneration, setLoadingGeneration] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(0);
  const { isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [accessToken, setAccessToken] = useState("");
  const [generations, setGenerations] = useState<Generation[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [recordingsIndex, setRecordingsIndex] = useState(0);
  const [templateRecordingsIndex, setTemplateRecordingsIndex] = useState(0);
  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);
  const [noteBuilderModalOpen, setNoteBuilderModalOpen] = useState(false);
  const [trialDaysRemaining, setTrialDaysRemaining] = useState(14);
  const [subscribed, setSubscribed] = useState(true);

  const handleOnboardingClose = (reason: string) => {
    if (reason === "close") {
      localStorage.setItem("onboarded", "true");
      setOnboardingModalOpen(false);
      setCurrentTabIndex("3");
    }
  };

  const handleNoteBuilderClose = (reason: string) => {
    setNoteBuilderModalOpen(false);
    localStorage.setItem("noteBuilderTutorial", "true");
  };

  const outputRef = React.useRef<HTMLTextAreaElement>(null);
  const recordingsReducer = (state: string[], action: Recording | null) => {
    if (action === null) {
      setRecordingsIndex(0);
      return [];
    } else if (action.consolidate) {
      setRecordingsIndex(1);
      return [action.recording];
    }
    var newState = [...state];
    newState.splice(
      action.index,
      1,
      (state[action.index] || "") + action.recording
    );
    return newState;
  };
  const [recordings, dispatchRecordings] = useReducer(recordingsReducer, []);

  const handleTemplateChange = React.useCallback((template: Template) => {
    setTemplates(template);
  }, []);
  const [selectedTemplate, setSelectedTemplate] = useState(
    templates.length > 0 ? templates[0].id : null
  );
  const templateRecordingsReducer = (
    state: string[],
    action: Recording | null
  ) => {
    const t = templates.find((t) => t.id === selectedTemplate);
    if (!t) return [];

    if (action === null) {
      setTemplateRecordingsIndex(0);
      t.saved = false;
      t.sample_dictation = "";
      handleTemplateChange(t);
      return [];
    } else if (action.consolidate) {
      setTemplateRecordingsIndex(1);
      t.saved = false;
      t.sample_dictation = action.recording;
      handleTemplateChange(t);
      return [action.recording];
    }
    var newState = [...state];
    newState.splice(
      action.index,
      1,
      (state[action.index] || "") + action.recording
    );
    t.saved = false;
    t.sample_dictation = newState.join(" ");
    handleTemplateChange(t);
    return newState;
  };
  const [, dispatchTemplateRecordings] = useReducer(
    templateRecordingsReducer,
    []
  );
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder>();
  const [loadingDictation, setLoadingDictation] = useState(false);
  const [recentDictations, setRecentDictations] = useState<Dictation[]>([]);
  const [recentDictationsModalOpen, setRecentDictationsModalOpen] =
    useState(false);
  const jobCountReducer = (state: number, action: number) => {
    if (action === 0) return 0;
    return state + action;
  };
  const [jobCount, dispatchJobCount] = useReducer(jobCountReducer, 0);
  const [currentTabIndex, setCurrentTabIndex] = useState("1");

  // Onboarding
  useEffect(() => {
    if (localStorage.getItem("onboarded") !== "true") {
      setOnboardingModalOpen(true);
    }
  }, []);

  // Template editor
  const handleNewTemplate = React.useCallback(() => {
    if (accessToken && url) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      fetch(url + "/templates/create", requestOptions).then((response) => {
        response.json().then((data: Template) => {
          setTemplates([...templates, data]);
          setSelectedTemplate(data.id);
        });
      });
    }
  }, [accessToken, url, templates]);

  const handleDeleteTemplate = React.useCallback(
    (id: number) => {
      if (accessToken && url) {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ id }),
        };
        fetch(url + "/templates/delete", requestOptions).then((response) => {
          if (response.status === 200) {
            const newTemplates = templates.filter((t) => t.id !== id);
            setTemplates(newTemplates);
            if (newTemplates.length > 0) {
              setSelectedTemplate(newTemplates[0].id);
            }
          }
        });
      }
    },
    [accessToken, url, templates]
  );

  const handleCloneTemplate = React.useCallback(
    (id: number) => {
      if (accessToken && url) {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ id }),
        };
        fetch(url + "/templates/clone", requestOptions).then((response) => {
          response.json().then((data: Template) => {
            setTemplates([...templates, data]);
            setSelectedTemplate(data.id);
          });
        });
      }
    },
    [accessToken, url, templates]
  );

  const handleSaveTemplateChange = React.useCallback(
    (template: Template) => {
      if (accessToken && url) {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(template),
        };
        fetch(url + "/templates/update", requestOptions).then((response) => {
          if (response.status === 200) {
            setTemplates(template);
          }
        });
      }
    },
    [accessToken, url]
  );

  const resultsReducer = (state: Result[], action: Result | null) => {
    if (action === null) {
      return [];
    }
    const newState = state.map((i) =>
      i.id === action.id
        ? {
            ...action,
            output: action.overwrite ? action.output : i.output + action.output,
          }
        : i
    );

    if (newState.findIndex((i) => i.id === action.id) === -1) {
      return [...newState, action];
    } else {
      return newState;
    }
  };
  const [results, dispatchResults] = useReducer(resultsReducer, []);

  // Fetch recent dictations
  const fetchDictations = React.useCallback(() => {
    if (accessToken && url) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      fetch(url + "/dictations/recent", requestOptions).then((response) => {
        response.json().then((data: Dictation[]) => {
          setRecentDictations(data);
        });
      });
    }
  }, [url, accessToken]);

  // Audio recording
  const handleStartRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        console.log("Got audio stream.");
        try {
          const startTime = new Date().getTime();
          const index = recordingsIndex;
          setRecordingsIndex(index + 1);
          const newMediaRecorder = new MediaRecorder(stream);
          console.log("Created MediaRecorder.");
          setMediaRecorder(newMediaRecorder);

          let chunks: Blob[] = [];
          newMediaRecorder.ondataavailable = function (e) {
            console.log("Adding data chunk");
            chunks.push(e.data);
          };

          newMediaRecorder.onstop = function (e) {
            const endTime = new Date().getTime();
            console.log("Stopping recording.");
            setLoadingDictation(true);
            stream.getTracks().forEach((track) => track.stop());

            const blob = new Blob(chunks);
            var fd = new FormData();
            fd.append("audio", blob);
            fd.append("mimeType", newMediaRecorder.mimeType);

            const requestOptions = {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              body: fd,
              onmessage(ev: any) {
                const data = JSON.parse(ev.data);
                if (data.output) {
                  dispatchRecordings({ index, recording: data.output });
                  setLoadingDictation(data.loading);
                }
              },
              onerror: (e: any) => {
                setLoadingDictation(false);
                fetchDictations();
                console.error(e);
              },
              onended: (e: any) => {
                fetchDictations();
                setLoadingDictation(false);
              },
              onclose: () => {
                fetchDictations();
                setLoadingDictation(false);
              },
            };

            if (endTime - startTime > 1000) {
              fetchEventSource(url + "/dictations", requestOptions);
            } else {
              setLoadingDictation(false);
              console.log("skipping short recording");
            }
            setMediaRecorder(undefined);
          };

          newMediaRecorder.start();
        } catch (e) {
          console.log(e);
          alert(e);
        }
      })
      .catch(function (err) {
        alert(err);
        console.log("The following getUserMedia error occured: " + err);
      });
  };

  const handleStartTemplateRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        console.log("Got audio stream.");
        try {
          const startTime = new Date().getTime();
          const index = templateRecordingsIndex;
          setTemplateRecordingsIndex(index + 1);
          const newMediaRecorder = new MediaRecorder(stream);
          console.log("Created MediaRecorder.");
          setMediaRecorder(newMediaRecorder);

          let chunks: Blob[] = [];
          newMediaRecorder.ondataavailable = function (e) {
            console.log("Adding data chunk");
            chunks.push(e.data);
          };

          newMediaRecorder.onstop = function (e) {
            const endTime = new Date().getTime();
            console.log("Stopping recording.");
            setLoadingDictation(true);
            stream.getTracks().forEach((track) => track.stop());

            const blob = new Blob(chunks);
            var fd = new FormData();
            fd.append("audio", blob);
            fd.append("mimeType", newMediaRecorder.mimeType);

            const requestOptions = {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              body: fd,
              onmessage(ev: any) {
                const data = JSON.parse(ev.data);
                if (data.output) {
                  dispatchTemplateRecordings({ index, recording: data.output });
                  setLoadingDictation(data.loading);
                }
              },
              onerror: (e: any) => {
                setLoadingDictation(false);
                fetchDictations();
                console.error(e);
              },
              onended: (e: any) => {
                fetchDictations();
                setLoadingDictation(false);
              },
              onclose: () => {
                fetchDictations();
                setLoadingDictation(false);
              },
            };

            if (endTime - startTime > 1000) {
              fetchEventSource(url + "/dictations", requestOptions);
            } else {
              setLoadingDictation(false);
              console.log("skipping short recording");
            }
            setMediaRecorder(undefined);
          };

          newMediaRecorder.start();
        } catch (e) {
          console.log(e);
          alert(e);
        }
      })
      .catch(function (err) {
        alert(err);
        console.log("The following getUserMedia error occured: " + err);
      });
  };

  const handleStopRecording = () => {
    mediaRecorder?.stop();
  };

  // Get recent generations
  const fetchGenerations = React.useCallback(() => {
    if (accessToken && url) {
      setLoadingGeneration(true);

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      fetch(url + "/generations", requestOptions).then((response) => {
        response.json().then((data: Generation[]) => {
          setGenerations(data);
          setLoadingGeneration(false);
        });
      });
    }
  }, [url, accessToken]);

  const handleViewGenerations = React.useCallback(() => {
    fetchGenerations();
    dispatchResults(null);
  }, [fetchGenerations]);

  const handleGenerationClick = React.useCallback(
    (id: string) => {
      return () => {
        setLoadingGeneration(true);
        setCurrentDocument(0);

        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        };

        fetch(url + `/generations/${id}`, requestOptions).then((response) => {
          response.json().then((data) => {
            data.forEach((d: Result) => {
              dispatchResults(d);
            });
            setHumanId(data[0].humanId);
            setLoadingGeneration(false);
          });
        });
      };
    },
    [accessToken, url]
  );

  const hydrateTemplate = React.useCallback(
    (title: string) => {
      const id = templates.findIndex((t) => t.title === title);
      return templates[id];
    },
    [templates]
  );

  // Form handlers
  const handleDocumentsChange = React.useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const {
        target: { value },
      } = event;
      const selectedTypes =
        typeof value === "string" ? value.split(",") : value;
      setSelectedTemplates(selectedTypes);
      localStorage.setItem(
        "selectedDocumentTypes",
        JSON.stringify(selectedTypes)
      );
    },
    []
  );

  const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatchRecordings({
      index: 0,
      recording: event.target.value,
      consolidate: true,
    });
  };

  const handleTemplateRecordingsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatchTemplateRecordings({
      index: 0,
      recording: event.target.value,
      consolidate: true,
    });
  };

  const handleSubmit = React.useCallback(
    (event: any) => {
      if (selectedTemplates.length === 0) {
        return;
      }
      setCurrentDocument(0);
      dispatchResults(null);
      dispatchJobCount(0);
      setExpectedDocumentCount(selectedTemplates.length);
      setLoading(true);
      const random1 = Math.floor(Math.random() * words.length - 1);
      const random2 = Math.floor(Math.random() * words.length - 1);
      const human_id = `${words[random1]}-${words[random2]}`;
      const generation_id = `gen_${crypto.randomUUID()}`;
      const templates = selectedTemplates.map((doc) => hydrateTemplate(doc));

      templates.forEach((t) => {
        const body = {
          template: t,
          notes: recordings.join(" "),
          human_id,
          generation_id,
        };
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(body),
          onmessage(ev: any) {
            const data = JSON.parse(ev.data);
            const newResult = {
              id: data.id,
              documentType: data.documentType,
              output: data.output,
              humanId: data.humanId,
              loading: data.loading,
            };
            dispatchResults(newResult);
            setHumanId(data.humanId);
          },
          onerror: (e: any) => {
            console.error(e);
          },
          onended: (e: any) => {
            dispatchJobCount(1);
          },
          onclose: () => {
            dispatchJobCount(1);
          },
        };

        fetchEventSource(url + "/documents", requestOptions);
      });
    },
    [accessToken, hydrateTemplate, recordings, selectedTemplates, url]
  );

  const handleOutputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatchResults({
        id: results[currentDocument].id,
        documentType: results[currentDocument].documentType,
        output: event.target.value,
        humanId: results[currentDocument].humanId,
        loading: false,
        overwrite: true,
      });
    },
    [currentDocument, results]
  );
  useEffect(() => {
    if (
      jobCount > 0 &&
      expectedDocumentCount > 0 &&
      jobCount === expectedDocumentCount
    ) {
      fetchGenerations();
      dispatchResults({
        id: "_prompt",
        documentType: "Original Prompt",
        output: recordings.join(" "),
        humanId: humanId,
        loading: false,
      });
      dispatchJobCount(0);
      setExpectedDocumentCount(0);
      setNoPHI(false);
      setLoading(false);
    }
  }, [jobCount, expectedDocumentCount, fetchGenerations, recordings, humanId]);

  const togglePHIAttestation = React.useCallback(
    (event: any) => {
      setNoPHI(!noPHI);
    },
    [noPHI]
  );

  // Document navigation
  const handleCopy = React.useCallback(
    (event: any) => {
      navigator.clipboard.writeText(results[currentDocument].output);
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 2000);
    },
    [currentDocument, results]
  );

  const handlePrevious = React.useCallback(
    (event: any) => {
      if (currentDocument - 1 < 0) {
        setCurrentDocument(results.length - 1);
      } else {
        setCurrentDocument((currentDocument - 1) % results.length);
      }
    },
    [currentDocument, results.length]
  );

  const handleNext = React.useCallback(
    (event: any) => {
      setCurrentDocument((currentDocument + 1) % results.length);
    },
    [currentDocument, results.length]
  );

  // Fetch templates
  useEffect(() => {
    if (accessToken && url) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      fetch(url + "/templates", requestOptions).then((response) => {
        response.json().then((data: Template[]) => {
          setTemplates(data.map((t) => ({ ...t, saved: true })));
          setSelectedTemplate(data[0].id);
        });
      });
    }
  }, [url, accessToken]);

  useEffect(() => {
    if (accessToken && url) {
      fetchDictations();
    }
  }, [accessToken, url, fetchDictations]);

  useEffect(() => {
    if (accessToken && url) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      fetch(url + "/subscriptions/status", requestOptions).then((response) => {
        response.json().then((data) => {
          const subscribed = data.subscribed;
          const trial = data.trial;
          setSubscribed(subscribed);
          setTrialDaysRemaining(data.trialDaysRemaining);
          if (!subscribed && !trial) {
            window.location.href = "/subscribe";
          }
        });
      });
    }
  }, [accessToken, url]);

  // Fetch and save access token
  useEffect(() => {
    if (!isLoading && !accessToken) {
      getAccessTokenSilently()
        .then((t) => {
          setAccessToken(t);
        })
        .catch(async (e) => {
          console.error(e);
          loginWithRedirect().then(() => {
            getAccessTokenSilently().then((t) => {
              setAccessToken(t);
            });
          });
        });
    }
  }, [getAccessTokenSilently, loginWithRedirect, isLoading, accessToken]);

  // Select recently selected templates from local storage
  useEffect(() => {
    if (templates.length > 0) {
      var lsDocumentTypes = localStorage.getItem("selectedDocumentTypes");
      if (lsDocumentTypes !== null) {
        const parsed: string[] = JSON.parse(lsDocumentTypes);
        if (
          parsed.length > 0 &&
          parsed.every((d) => templates.includes(hydrateTemplate(d)))
        ) {
          setSelectedTemplates(parsed);
        } else {
          setSelectedTemplates([templates[0].title]);
          localStorage.setItem(
            "selectedDocumentTypes",
            JSON.stringify([templates[0]])
          );
        }
      } else {
        setSelectedTemplates([templates[0].title]);
        localStorage.setItem(
          "selectedDocumentTypes",
          JSON.stringify([templates[0]])
        );
      }
    }
  }, [templates, hydrateTemplate]);

  // Fetch generations
  useEffect(() => {
    if (url && accessToken) {
      fetchGenerations();
    }
  }, [fetchGenerations, url, accessToken]);

  const LoadingView = (): JSX.Element => {
    return (
      <Box alignItems="center">
        <Stack spacing={2} alignItems="center">
          <Menu
            trialDaysRemaining={14}
            subscribed={subscribed}
            accessToken={accessToken}
          />
          <CircularProgress />
        </Stack>
      </Box>
    );
  };

  if (isLoading || !accessToken || !url) {
    return <LoadingView />;
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === "2") {
      if (localStorage.getItem("noteBuilderTutorial") !== "true") {
        setNoteBuilderModalOpen(true);
      }
    }

    setCurrentTabIndex(newValue);
  };

  return (
    <Box>
      <Menu
        trialDaysRemaining={trialDaysRemaining}
        subscribed={subscribed}
        accessToken={accessToken}
      />
      <TabContext value={currentTabIndex}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={currentTabIndex} onChange={handleTabChange}>
            <Tab label="Documents" value="1" />
            <Tab label="Note Builder" value="2" />
            <Tab label="Tutorials" value="3" />
          </Tabs>
        </Box>
        <TabPanel value="1" sx={{ p: 1 }}>
          <Grid2 container columns={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2 }}>
            <Grid2 xs={1} sm={1} md={1} lg={1} xl={1}>
              <Form
                loading={loading}
                loadingDictation={loadingDictation}
                templates={templates}
                selectedTemplates={selectedTemplates}
                noPHI={noPHI}
                handleDocumentsChange={handleDocumentsChange}
                handleNotesChange={handleNotesChange}
                handleSubmit={handleSubmit}
                togglePHIAttestation={togglePHIAttestation}
                handleStartRecording={handleStartRecording}
                handleStopRecording={handleStopRecording}
                recordings={recordings}
                dispatchRecordings={dispatchRecordings}
                recentDictationsModalOpen={recentDictationsModalOpen}
                recordingsIndex={recordingsIndex}
                setRecentDictationsModalOpen={setRecentDictationsModalOpen}
                dictations={recentDictations}
                outputRef={outputRef}
              />
            </Grid2>
            <Grid2 xs={1} sm={1} md={1} lg={1} xl={1}>
              {results.length > 0 ? (
                <Results
                  results={results}
                  currentDocument={currentDocument}
                  showCopied={showCopied}
                  humanId={humanId}
                  loading={loading}
                  handleNext={handleNext}
                  handleOutputChange={handleOutputChange}
                  handlePrevious={handlePrevious}
                  handleCopy={handleCopy}
                  handleViewGenerations={handleViewGenerations}
                  outputRef={outputRef}
                />
              ) : (
                <Generations
                  hidden={loading}
                  generations={generations}
                  handleGenerationClick={handleGenerationClick}
                  refreshGenerations={fetchGenerations}
                  loading={loadingGeneration}
                  page={currentPage}
                  setPage={setCurrentPage}
                />
              )}
            </Grid2>
          </Grid2>
        </TabPanel>
        <TabPanel value="2" sx={{ p: 1 }}>
          <Grid2 container columns={{ xs: 1, sm: 1, md: 12, lg: 12, xl: 12 }}>
            <Grid2 xs={1} sm={1} md={4}>
              <TemplateList
                templates={templates}
                setSelectedTemplate={setSelectedTemplate}
                selectedTemplate={selectedTemplate}
                handleNewTemplate={handleNewTemplate}
                handleDeleteTemplate={handleDeleteTemplate}
                handleCloneTemplate={handleCloneTemplate}
              />
            </Grid2>
            <Divider orientation="vertical" flexItem sx={{ mr: "-1px" }} />
            <Grid2 xs={1} sm={1} md={8}>
              <TemplateForm
                templates={templates}
                template={templates.find((t) => t.id === selectedTemplate)}
                handleTemplateChange={handleTemplateChange}
                handleSaveTemplateChange={handleSaveTemplateChange}
                handleStartTemplateRecording={handleStartTemplateRecording}
                handleStopRecording={handleStopRecording}
                outputRef={outputRef}
                loading={loading}
                loadingDictation={loadingDictation}
                dispatchTemplateRecordings={dispatchTemplateRecordings}
                handleTemplateRecordingsChange={handleTemplateRecordingsChange}
              />
            </Grid2>
          </Grid2>
        </TabPanel>
        <TabPanel value="3" sx={{ p: 2 }}>
          <Stack spacing={2} direction="column" alignItems="start">
            <Typography variant="h4" color="textPrimary" textAlign="center">
              <b>Tutorials</b>
            </Typography>
            <Typography variant="h5" color="textPrimary" textAlign="center">
              <b>User Guide</b>
            </Typography>
            <Typography variant="body1" color="white">
              Check out the{" "}
              <Link
                href="https://dotphrase.notion.site"
                target="_blank"
                rel="noopener"
              >
                User Guide here
              </Link>
              .
            </Typography>
            <Typography variant="h5" color="textPrimary" textAlign="center">
              <b>Video Tutorials</b>
            </Typography>
            <Box sx={{ width: "1024px", maxWidth: "90vw", height: "80vh" }}>
              <iframe
                loading="lazy"
                style={{
                  border: "none",
                  width: "100%",
                  height: "100%",
                }}
                src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFlEfAaXLA&#x2F;watch?embed"
                allow="fullscreen"
                title="Dotphrase Tutorials (Part 1): Creating and Modifying Notes"
              ></iframe>
            </Box>
            <Box sx={{ width: "1024px", maxWidth: "90vw", height: "80vh" }}>
              <iframe
                loading="lazy"
                style={{
                  border: "none",
                  width: "100%",
                  height: "100%",
                }}
                src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFlLa2K6bs&#x2F;watch?embed"
                allow="fullscreen"
                title="Dotphrase Tutorials (Part 2): Using the Note Builder"
              ></iframe>
            </Box>
          </Stack>
        </TabPanel>
      </TabContext>
      <Modal
        open={onboardingModalOpen}
        onClose={(event, reason) => handleOnboardingClose(reason)}
        disableEscapeKeyDown
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            width: "80vw",
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0, p: 2 }}
            onClick={() => handleOnboardingClose("close")}
          >
            <CloseIcon />
          </IconButton>
          <Stack
            spacing={2}
            direction="column"
            alignItems="start"
            sx={{ maxWidth: "1024px", height: "80vh" }}
          >
            <Typography variant="h4" color="textPrimary" textAlign="center">
              <b>Welcome to dotphrase.ai!</b>
            </Typography>
            <Typography variant="body1" color="white">
              Dotphrase.ai is a tool for generating clinical notes from
              templates. Watch the quick tutorial video below to get started.
            </Typography>
            <iframe
              loading="lazy"
              style={{
                border: "none",
                width: "100%",
                height: "100%",
              }}
              src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFk_-CGHO8&#x2F;watch?embed"
              allow="fullscreen"
              title="How to: create your first note in seconds"
            ></iframe>
          </Stack>
        </Box>
      </Modal>
      <Modal
        open={noteBuilderModalOpen}
        onClose={(event, reason) => handleNoteBuilderClose(reason)}
        disableEscapeKeyDown
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            width: "80vw",
          }}
        >
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0, p: 2 }}
            onClick={() => handleNoteBuilderClose("close")}
          >
            <CloseIcon />
          </IconButton>
          <Stack
            spacing={2}
            direction="column"
            alignItems="start"
            sx={{ maxWidth: "1024px", height: "80vh" }}
          >
            <Typography variant="h4" color="textPrimary" textAlign="center">
              <b>Note Builder Tutorial</b>
            </Typography>
            <Typography variant="body1" color="white">
              Using our flexible and easy-to-use note builder, you can create
              your own templates in seconds. Watch the quick tutorial video
              below to get started.
            </Typography>
            <iframe
              loading="lazy"
              style={{
                border: "none",
                width: "100%",
                height: "100%",
              }}
              src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAFlLa2K6bs&#x2F;watch?embed"
              allow="fullscreen"
              title="Using the Note Builder"
            ></iframe>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

export default App;
