import {
  Stack,
  Typography,
  Box,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import { Result } from "../types/Result";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

type ResultsProps = {
  results: Result[];
  currentDocument: number;
  showCopied: boolean;
  humanId: string;
  loading: boolean;
  handleNext: (event: any) => void;
  handlePrevious: (event: any) => void;
  handleOutputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleViewGenerations: (event: any) => void;
  handleCopy: (event: any) => void;
  outputRef: React.RefObject<HTMLTextAreaElement>;
};

const Results = (props: ResultsProps): JSX.Element => {
  const {
    results,
    currentDocument,
    showCopied,
    humanId,
    loading,
    handleNext,
    handlePrevious,
    handleCopy,
    handleOutputChange,
    handleViewGenerations,
    outputRef,
  } = props;

  return results.length > 0 ? (
    <Stack spacing={1} alignItems={"center"} sx={{ margin: 2 }}>
      <Stack alignItems={"center"} spacing={1}>
        {!loading && (
          <Stack
            sx={{ width: "100%" }}
            direction="column"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Button
              onClick={handleViewGenerations}
              disabled={results.length < 1}
              startIcon={<ArrowBackIcon />}
              variant="outlined"
              color="info"
            >
              Recent Documents
            </Button>
            <Typography color="textSecondary" variant="body1">
              {humanId}
            </Typography>
          </Stack>
        )}

        <Stack alignItems={"center"} direction="row" spacing={1}>
          <Button
            onClick={handleCopy}
            disabled={results.length < 1}
            endIcon={<ContentPasteIcon />}
          >
            Copy
          </Button>
          <Button
            disabled={results.length < 2}
            onClick={handlePrevious}
            endIcon={<KeyboardArrowLeftIcon />}
          >
            Prev
          </Button>
          <Button
            disabled={results.length < 2}
            onClick={handleNext}
            startIcon={<KeyboardArrowRightIcon />}
          >
            Next
          </Button>
          <Typography color="textPrimary" variant="body1">
            {currentDocument + 1} / {results.length}
          </Typography>
        </Stack>
        {showCopied && (
          <Typography color="textPrimary" variant="body1">
            ✓ Contents copied to clipboard.
          </Typography>
        )}
      </Stack>
      <Box
        sx={{
          backgroundColor: "#1F232B",
          borderRadius: 4,
          width: "100%",
        }}
      >
        <Stack sx={{ padding: 2 }} alignItems={"center"} spacing={2}>
          <Stack direction="row" spacing={2} alignItems="center">
            {results[currentDocument]?.loading && (
              <CircularProgress size={24} />
            )}
            <Typography color="textPrimary" variant="h6">
              {results[currentDocument]?.documentType}
            </Typography>
          </Stack>
          <TextField
            sx={{
              paddingBottom: 2,
              width: "100%",
            }}
            multiline
            variant="filled"
            onChange={handleOutputChange}
            value={results[currentDocument]?.output || ""}
            disabled={results[currentDocument]?.loading}
            inputRef={outputRef}
          />
        </Stack>
      </Box>
    </Stack>
  ) : (
    <div></div>
  );
};

export default Results;
