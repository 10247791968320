import {
  Toolbar,
  Button,
  Avatar,
  Box,
  AppBar,
  Typography,
  Stack,
  Link,
} from "@mui/material";
import Profile from "./Profile";
import logo from "../assets/logo.png";

type MenuProps = {
  subscribed: boolean;
  trialDaysRemaining: number;
  accessToken: string | undefined;
};

const Menu = (props: MenuProps): JSX.Element => {
  const { trialDaysRemaining, subscribed, accessToken } = props;
  return (
    <AppBar position="static" sx={{ backgroundColor: "#090E16" }}>
      <Toolbar>
        <Button href={window.location.origin}>
          <Avatar
            alt="dotphrase.ai"
            src={logo}
            variant="square"
            sx={{ width: 220, height: 50, p: 1 }}
          />
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <Link
          sx={{ display: { xs: "none", sm: "block" }, marginRight: 2 }}
          href="https://dotphrase.notion.site"
          target="_blank"
          rel="noopener"
          underline="none"
        >
          User Guide
        </Link>
        <Profile accessToken={accessToken} />
      </Toolbar>
      {subscribed === false && (
        <Box sx={{ width: "100%", backgroundColor: "#f39c12" }}>
          <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            sx={{ ml: 1 }}
            justifyContent={"space-between"}
          >
            <Typography variant="subtitle2" color="white">
              <b>
                You have {trialDaysRemaining < 0 ? 0 : trialDaysRemaining} days
                left in your free trial
              </b>
            </Typography>
            <Link href="/subscribe" sx={{ pr: 2, pt: 1, pb: 1 }}>
              <b>Upgrade Now</b>
            </Link>
          </Stack>
        </Box>
      )}
    </AppBar>
  );
};

export default Menu;
